import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header'
import Footer from './Footer'
import './layout.scss'

export default class Layout extends React.Component {
  render() {
      return <LayoutComponent {...this.props} />
  }
}

const LayoutComponent = ({ children, pageID, headerTheme, headerClasses, footerCta, footerLogoColour }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          defaultTitle={`${data.site.siteMetadata.title} | Empower your business`}
          titleTemplate={`%s | ${data.site.siteMetadata.title}`}
          meta={[
            { name: 'description', content: 'Specialising in all things web - Wordpress, ReactJS, GatsbyJS, PHP and more. Full website builds to per project based work.' },
            { name: 'keywords', content: 'web development, search engine optimisation, conversion rate optimisation, wordpress, reactjs, gatsby' },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} theme={headerTheme} classes={headerClasses} />
        <div id={pageID} className="pageBlock">{children}</div>
        <Footer logoColour={footerLogoColour} ctaBlock={footerCta} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}