import React from 'react'
import { Link } from 'gatsby'

import Logo from 'src/elements/Logo'
//import Social from 'src/elements/Social'

import './style.scss';

const MenuItem = ({link, label}) => {
  return (
    <div className="menuItem">
      <Link to={link} activeClassName="active">{label}</Link>
    </div>
  )
}

export default class Header extends React.Component {

  constructor(props) {
    super(props);
    this.toggleClass= this.toggleClass.bind(this);
    this.state = {
        active: false,
    };
  }
  toggleClass() {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
  };

  render() {
    let themeVars = {
      headerClass: 'dark',
      logoColour: 'default'
    }
  
    switch (this.props.theme) {
      case 'light':
        themeVars = {
          headerClass: 'light',
          logoColour: 'green-white'
        }
        break;
    }

    return (
      <>
        <header className={themeVars.headerClass+' '+(this.props.classes || '')}>
          <div className="wrap">
            <Logo colour={themeVars.logoColour || 'default'} />
            <span id="mmShroud" className={this.state.active ? ' active': ''} onClick={this.toggleClass}></span>
            <div className={`menuItems${this.state.active ? ' active': ''}`}>
              <span id="mmClose" onClick={this.toggleClass} ></span>
              <MenuItem link="/services/" label="Services" />
              <MenuItem link="/how-we-help/" label="How we help" />
              <MenuItem link="/contact-us/" label="Contact us" />
            </div>
            <span id="mmHandle" onClick={this.toggleClass}></span>
          </div>
        </header>
      </>
    )
  }
}
/* <MenuItem link="/tools/" label="Tools" /> */