import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import Logo from 'src/elements/Logo'
import CtaBlock from 'src/components/CtaBlock'

import './style.scss';

const MenuColumn = ({paths}) => {
  const menuItems = paths.map((service, i) => {
    const info = service.node.frontmatter
    return (
      <li key={i}><Link to={info.path}>{info.menuLabel}</Link></li>
    )
  })
  return <ul>{menuItems}</ul>
}

const DisplayCta = (display) => {
  if (display || typeof display === 'undefined') {
    return <CtaBlock />
  } else return null
}

const Footer = ({ siteTitle, logoColour, ctaBlock }) => (
  <StaticQuery
    query={graphql`
      query FooterMenuQuery {
        services: allMarkdownRemark(filter:{frontmatter:{path:{regex: "/services/"}}}) {
          edges {
            node {
              frontmatter {
                path
                menuLabel
              }
            }
          }
        }

        howWeHelp: allMarkdownRemark(filter:{frontmatter:{path:{regex: "/how-we-help/"}}}) {
          edges {
            node {
              frontmatter {
                path
                menuLabel
              }
            }
          }
        }
      }
    `}
    /*
      tools: allJavascriptFrontmatter(filter:{frontmatter:{path:{regex: "/tools/"}}}) {
          edges {
            node {
              frontmatter {
                path
                menuLabel
              }
            }
          }
        }
    */
    render={data => (
      <>
        <DisplayCta display={ctaBlock} />
        <footer>
          <div className="wrap">
            <div className="elements">
              <Logo colour={logoColour || 'default'} />
              <div className="menuItems">
                <div className="menuColumn mmfull">
                  <h6><Link to="/services/">Services</Link></h6>
                  <MenuColumn paths={data.services.edges} />
                </div>
                <div className="menuColumn">
                  <h6><Link to="/how-we-help/">How we help</Link></h6>
                  <MenuColumn paths={data.howWeHelp.edges} />
                </div>
                <div className="menuColumn">
                  <h6><Link to="/contact-us/">Contact us</Link></h6>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright">
            &copy; {(new Date().getFullYear())} ARD Web Development
          </div>
        </footer>
      </>
    )}
  />
)

export default Footer
 /*
      <div className="menuColumn">
                  <h6><Link to="/tools/">Tools</Link></h6>
                  <MenuColumn paths={data.tools.edges} />
                </div>
 */