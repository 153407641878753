import React from 'react'
import { Link } from 'gatsby'

import "./style.scss"

export default class CtaBlock extends React.Component {
    
  
    render() {
        return (
            <section id="ctablock" className={`${this.props.classes || null}`}>
                <div className="wrap">
                    <span className="hookTag">Increase your productivity by concentrating on what you love doing.</span>
                    <span className="reelTag">Chat today about how we can help improve your projects.</span>
                    <Link className="btn" to="/contact-us/">Let's chat</Link>
                </div>
            </section>
        );
    }
}