import React from 'react'
import { StaticQuery, Link, graphql } from "gatsby"

import './logo.scss';

const LinkOutput = ({ children, to, activeClassName, ...other }) => {
    const internal = /^\/(?!\/)/.test(to)
  
    if (internal) {
      return (
        <Link to={to} activeClassName={activeClassName} {...other}>
          {children}
        </Link>
      )
    }
    return (
      <a target="_blank" rel="noopener noreferrer" href={to} {...other}>
        {children}
      </a>
    )
  }

const LinkPrep = ({link, logoPath, text}) => {
    const logo = logoPath ? {'backgroundImage':(logoPath === "none" ? logoPath : 'url('+logoPath+')')} : null;

    return (
        <LinkOutput to={link} style={logo}>
            {text}
        </LinkOutput>
    )
}

export default props => (
    <StaticQuery
      query={graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
      `
      }
      render = {data => {
        return (
        <div className={`logo ${props.colour || 'default'}`}>
            <LinkPrep link={`${props.link || '/'}`} logoPath={props.logo || null} text={props.name || data.title} />
        </div>
        )
      }}
    />
)